import { AppSettings as appSettings } from '../../AppSettings';
import { ListarAtivosRequest } from '../ativos/ativoAPI';
import { ListarContratosRequest } from '../contratos/contratoAPI';
import { ListarProcessamentoNotasCorretagemRequest, ProcessamentoNotasCorretagemRequest } from '../importarNotas/importarNotasAPI';
import { ListarLancamentoContaRequest } from '../lancamentosConta/lancamentoContaAPI';
import { ListarProdutosRequest } from '../produtos/produtoAPI';

export default class ConnectionAPI {
    baseUrl: string;
    header: any;
    constructor(baseUrl: string = appSettings.BaseUrlAPI as string, defaultHeaders: any) {
        this.baseUrl = baseUrl;
        this.header = defaultHeaders;
    }

    static Endpoints = {
        GetUsuarioSSO: (authority: string, username: string, uniqueId: string) => 'api/usuariosso?authority=' + authority + '&username=' + username + '&uniqueId=' + uniqueId,
        ProcessarNotas: 'api/processarnotas',
        ListarLancamentoConta: (request: ListarLancamentoContaRequest) => 'api/listarlancamentosconta?contas='
            + JSON.stringify(request.contas) + '&tiposLancamento='
            + JSON.stringify(request.tiposLancamento) + '&dataInicial='
            + request.dataInicial + '&dataFinal=' + request.dataFinal + '&pagina=' + request.pagina + '&tamanhoPagina=' + request.tamanhoPagina,
        AdicionarLancamentosConta: 'api/AdicionarLancamentosConta',
        AtualizarLancamentosConta: 'api/AtualizarLancamentosConta',
        DeleteLancamentosConta: 'api/DeleteLancamentosConta',
        GerarProcessarNotasCorretagem: 'api/GerarProcessarNotasCorretagem',
        ListarLancamentosPendenteAprovacao: (request: Partial<ListarProcessamentoNotasCorretagemRequest>) => 'api/ListarLancamentosPendenteAprovacao?contasId=' + JSON.stringify(request.contasId as string[]) + '&pagina=' + request.pagina + '&tamanhoPagina=' + request.tamanhoPagina,
        AprovarProcessamentoPendente: 'api/AprovarProcessamentoPendente',
        RejeitarProcessamentoPendente: 'api/RejeitarProcessamentoPendente',
        ListarAtivos: (request: ListarAtivosRequest) => 'api/ListarAtivos?contas='
            + JSON.stringify(request.contas) + '&tipos='
            + JSON.stringify(request.tipos) + '&dataInicial='
            + request.dataInicial + '&dataFinal=' + request.dataFinal + '&pagina=' + request.pagina + '&tamanhoPagina=' + request.tamanhoPagina,
        AdicionarAtivo: 'api/AdicionarAtivo',
        AtualizarAtivo: 'api/AtualizarAtivo',
        KPIDadosConta: (contas: string[]) => 'api/KPIDadosConta?contas=' + JSON.stringify(contas),
        KPIRentablidadeConta: (contas: string[]) => 'api/KPIRentablidadeConta?contas=' + JSON.stringify(contas),
        ListarContratos: (request: ListarContratosRequest) => 'api/ListarContratos?contas='
            + JSON.stringify(request.contas) + '&modelos='
            + JSON.stringify(request.modelos) + '&dataInicial='
            + request.dataInicial + '&dataFinal=' + request.dataFinal + '&pagina=' + request.pagina + '&tamanhoPagina=' + request.tamanhoPagina,
        ListarContratosContas: (request: Partial<ListarContratosRequest>) => 'api/ListarContratosConta?contas='
            + JSON.stringify(request.contas) + '&pagina=' + request.pagina + '&tamanhoPagina=' + request.tamanhoPagina,
        ListarModelosContrato: 'api/ListarModelosContrato',
        AdicionarContrato: 'api/AdicionarContrato',
        AtualizarContrato: 'api/AtualizarContrato',
        KPIContratoConta: (contas: string[], competencia: string) => 'api/KPIContratoConta?contas=' + JSON.stringify(contas) + '&competencia=' + competencia,
        ProcessarKPIRentablidadeConta: (contas: string[]) => 'api/ProcessarKPIRentablidadeConta?contas=' + JSON.stringify(contas),
        ProcessarKPIContratoConta: (contas: string[]) => 'api/ProcessarKPIContratoConta?contas=' + JSON.stringify(contas),
        ConsultarRelatorioPosicaoAtivo: (contas: string[], dataBase: string, pagina: number, tamanhoPagina: number) => 'api/ConsultarPosicaoAtivos?contas=' + JSON.stringify(contas) + '&dataBase=' + dataBase + '&pagina=' + pagina + '&tamanhoPagina=' + tamanhoPagina,
        MonitorarAtivo: 'api/MonitorarAtivo',
        ConsultarRelatorioAtivoPendenteNormalizacao: (pagina: number, tamanhoPagina: number, contas: string[]) => 'api/ListarPendentesNormalizacao?pagina=' + pagina + '&tamanhoPagina=' + tamanhoPagina + '&contas=' + JSON.stringify(contas),
        NormalizarAtivosPendentesProduto: 'api/NormalizarProdutos',
        ListarProdutos: (request: ListarProdutosRequest) => 'api/ListarProdutos?tipos='
            + JSON.stringify(request.tipos) + '&pagina=' + request.pagina + '&tamanhoPagina=' + request.tamanhoPagina,
        AdicionarProduto: 'api/AdicionarProduto',
        AtualizarProduto: 'api/AtualizarProduto',
    }

    async Get<T>(url: string, headers: any = {}): Promise<T> {
        console.log("URL", this.baseUrl + url);
        let localHeader = { ...this.header, ...headers };
        let localUrl = new URL("", this.baseUrl + url);
        var response = await fetch(localUrl, {
            headers: localHeader,
            method: 'GET',

        });
        return response.json() as Promise<T>;
    }


    async Post<T>(url: string, body: any = {}, headers: any = {}): Promise<T> {
        console.log("URL", this.baseUrl + url);
        let localHeader = { ...this.header, ...headers };
        let localUrl = new URL("", this.baseUrl + url);
        var response = await fetch(localUrl, {
            headers: localHeader,
            method: 'POST',
            body:  body
        });
        console.log(response);
        //if(response.ok){
        return response.json() as Promise<T>;
        //}
    }
    async Put<T>(url: string, body: any = {}, headers: any = {}): Promise<T> {
        console.log("URL", this.baseUrl + url);
        let localHeader = { ...this.header, ...headers };
        let localUrl = new URL("", this.baseUrl + url);
        var response = await fetch(localUrl, {
            headers: localHeader,
            method: 'PUT',
            body: body
        });
        console.log(response);
        if (response.status == 204) {
            return Promise.resolve(undefined) as Promise<T>;
        } else {
            return response.json() as Promise<T>;
        }
    }

    async Delete<T>(url: string, body: any = {}, headers: any = {}): Promise<T> {
        console.log("URL", this.baseUrl + url);
        let localHeader = { ...this.header, ...headers };
        let localUrl = new URL("", this.baseUrl + url);
        var response = await fetch(localUrl, {
            headers: localHeader,
            method: 'DELETE',
            body: body
        });
        console.log(response);
        //if(response.ok){
        return response.json() as Promise<T>;
        //}
    }
}

export const DefaultConnection: ConnectionAPI = new ConnectionAPI(appSettings.BaseUrlAPI, {});